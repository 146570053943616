<template>
  <container-list-extend title="Visite" class="d-flex flex-column w-100">
    <template v-slot:title>
      <div class="w-100 d-flex justify-content-between align-items-center p-2">
        <div class="d-flex flex-column flex-md-row align-items-center">
          <div class="mr-3">Animation n°{{ animation.id }}</div>
          <div class="mr-3 text-primary" v-if="!animation.anim_societe_id">Nouvelle animation à saisir</div>
          <description-from-list
            v-if="data && animation.anim_societe_id"
            class="mr-3 text-primary"
            :value="animation.anim_societe_id"
            :data="data.societes"
            text
          ></description-from-list>
          <description-from-list
            v-if="data && animation.anim_theme_id"
            class="text-muted mr-2"
            :value="animation.anim_theme_id"
            :data="data.animationThemes"
            text
          ></description-from-list>
        </div>
        <div>
          <description-from-list
            v-if="data && animation.anim_type_id"
            class="mr-3 mb-1 badge badge-warning"
            :value="animation.anim_type_id"
            :data="data.animationTypes"
            text
          ></description-from-list>
          <small class="text-muted">
            {{ $moment(animation.anim_date).format("DD/MM/YYYY") }}
          </small>
        </div>
      </div>
    </template>
    <template v-slot:form>
      <div class="d-flex align-items-center p-3">
        <menuItem
          title="informations"
          @click="tab = 'informations'"
          :active="tab === 'informations'"
          icon="info"
        ></menuItem>
        <menuItem title="actions" @click="tab = 'actions'" :active="tab === 'actions'" icon="check-circle"></menuItem>
        <menuItem title="documents" @click="tab = 'documents'" :active="tab === 'documents'" icon="file"></menuItem>
        <menuItem
          title="participants"
          @click="tab = 'participants'"
          :active="tab === 'participants'"
          icon="users"
        ></menuItem>
      </div>
      <base-form
        deleteBtn
        :loading="loading"
        @click="updateEvenement(animation)"
        @delete="deleteEvenement(animation)"
        v-if="tab === 'informations'"
      >
        <select-collaborateur
          class="mb-3"
          v-model.number="animation.anim_pilote_id"
          :text="'Pilote'"
        ></select-collaborateur>
        <base-form-row row>
          <base-inputDatePicker
            v-model="animation.anim_date"
            inputText="Date"
            :errors="feedback.anim_date"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Société"
            v-model.number="animation.anim_societe_id"
            :options="data.societes"
            :errors="feedback.anim_societe_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Type"
            v-model.number="animation.anim_type_id"
            :options="data.animationTypes"
            :errors="feedback.anim_type_id"
          ></base-select>
          <base-select
            inputText="Thème"
            v-model.number="animation.anim_theme_id"
            :options="data.animationThemes"
            :errors="feedback.anim_theme_id"
          ></base-select>
        </base-form-row>

        <base-form-row row>
          <base-input-area
            v-model="animation.anim_commentaire"
            text="Commentaire"
            :errors="feedback.anim_commentaire"
          ></base-input-area>
        </base-form-row>
        <div class="text-muted">
          <p>
            Veuillez indiquer dans le champ commentaire ci-dessus les conditions dans laquelle s'est déroulée
            l'animation (présentiel, si oui indiquez où, visio ou par téléphone), indiquez également les remarques,
            questions ou propositions apportées par les participants.
          </p>
        </div>
      </base-form>
      <div class="w-100 p-md-3" v-if="tab === 'participants'">
        <participants v-if="animation.id" :animation_id="animation.id"></participants>
      </div>
      <div class="w-100 p-md-3" v-if="tab === 'documents'">
        <uploader
          v-if="animation.id"
          :id="'document_qsser_animations' + animation.id"
          url="/upload/document"
          title="Déposer vos fichiers"
          :file_system="'QSSER/animations'"
          :file_field="'animation'"
          :file_key="animation.id"
        ></uploader>
      </div>
    </template>
    <template v-slot:actions>
      <actions-linked v-if="tab === 'actions'" :origine_system="31" :origine_id="animation.id"></actions-linked>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
//import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import Uploader from "@/components/bases/Uploader.vue";
import actionsLinked from "@/components/qsser/actionsLinked.vue";
import menuItem from "@/components/qsser/menuItem.vue";
import DescriptionFromList from "@/components/bases/DescriptionFromList";
import Participants from "@/components/qsser/animationsItemParticipants.vue";
import SelectCollaborateur from "@/components/bases/SelectCollaborateur.vue";

export default {
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    //BaseInput,
    BaseSelect,
    actionsLinked,
    Uploader,
    menuItem,
    DescriptionFromList,
    Participants,
    SelectCollaborateur,
  },
  data() {
    return {
      old: false,
      loading: false,
      feedback: {},
      tab: "informations",
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      _updateEvenement: "qsser/updateAnimations",
      _deleteEvenement: "qsser/deleteAnimations",
    }),
    updateEvenement: function (animation) {
      this.feedback = {};
      this.loading = true;
      this._updateEvenement(animation)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteEvenement: function (animation) {
      this.feedback = {};
      this.loading = true;
      this._deleteEvenement(animation)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  props: ["animation", "actions"],
  computed: {
    ...mapGetters({
      data: "qsser/data",
    }),
  },
};

//Route::middleware('permission:get_qsser')->get('/qsser/animation_participants/{id}', 'Qwark\QsserController@getParticipants');
//Route::middleware('permission:add_qsser_anim')->put('/qsser/animation_participants', 'Qwark\QsserController@storeParticipant');
//Route::middleware('permission:del_qsser_anim')->delete('/qsser/animation_participants/{id}', 'Qwark\QsserController@deleteParticipant');
</script>
<style></style>
