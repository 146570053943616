<template>
  <div>
    <div v-if="!loading" class>
      <div class="w-100">
        <h4 class="m-0">Participants</h4>
        <div class="pt-2 pb-2">
          <select-collaborateur
            v-model.number="collab_id"
            @collabSelected="createParticipant(collab_id)"
            :text="'Ajouter un collaborateur aux participants'"
          ></select-collaborateur>
        </div>
        <div v-for="p in participants" :key="p.id">
          <div
            v-if="p.animation_id === animation_id"
            class="d-flex justify-content-between align-items-center pt-1"
          >
            <div class="d-flex align-items-center">
              <AppAvatar
                :url="getCollabInfosById(p.collaborateur_id)['collaborateur_avatar']"
                propSize="2rem"
                ref="avatar"
                class="mr-2"
              ></AppAvatar>
              <div>
                <span class="mr-1">{{ getCollabInfosById(p.collaborateur_id)["collaborateur_nom"] }}</span>
                <span>{{ getCollabInfosById(p.collaborateur_id)["collaborateur_prenom"] }}</span>
              </div>
            </div>
            <div
              v-if="getCollabInfosById(p.collaborateur_id)['collaborateur_nom'] !== 'Confidentiel' "
              @click="deleteParticipant(p)"
              class="text-primary cursor-pointer"
            >supprimer</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading">Chargement...</div>
  </div>
</template>
<script>
import AppAvatar from "@/components/bases/Avatar.vue";
import SelectCollaborateur from "@/components/bases/SelectCollaborateur.vue";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
import { mapMultiRowFields } from "vuex-map-fields";

export default {
  components: { SelectCollaborateur, AppAvatar },
  data() {
    return {
      old: false,
      loading: false,
      feedback: {},
      collab_id: 0,
      test: [],
      collaborateurs: []
    };
  },
  mounted() {
    this.getParticipants();
  },
  methods: {
    ...mapActions({
      _getParticipants: "qsser/getParticipants",
      _createParticipant: "qsser/createParticipant",
      _deleteParticipant: "qsser/deleteParticipant"
    }),
    getParticipants: function() {
      this.feedback = {};
      this.loading = true;
      this._getParticipants(this.animation_id)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => this.getCollaborateursByIds());
    },
    createParticipant: function(collab_id) {
      this.feedback = {};
      this.loading = true;
      const data = {
        animation_id: this.animation_id,
        collaborateur_id: collab_id
      };
      this._createParticipant(data)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => {
          this.collab_id = 0;
          this.loading = false;
        });
    },
    deleteParticipant: function(data) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null
      this.feedback = {};
      this.loading = true;
      this._deleteParticipant(data)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    getCollabInfosById(id) {
      if (this.collaborateurs && this.collaborateurs.length > 0) {
        const index = this.collaborateurs.findIndex(x => x.id === id);

        if (index === -1)
          return {
            collaborateur_nom: "Confidentiel",
            collaborateur_prenom: "",
            collaborateur_avatar: null
          };
        return this.collaborateurs[index];
      }
    },
    getCollaborateursByIds() {
      let idArr = [];
      this.participants.forEach(p => { idArr.push(p.collaborateur_id) })
      this.loading = true;
      this.$http
      .post("/collaborateurs/byids", { ids:idArr })
      .then((response) => (this.collaborateurs = response.data))    
      .finally(() => (this.loading = false));
    }
  },
  props: ["animation_id"],
  computed: {
    ...mapMultiRowFields("qsser", ["participants"]),
  }
};
</script>
<style></style>
